import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import { Container, SEO } from "../components"

const Index = () => {
  return (
    <Container
      index="true"
      title="Krishna Prasanth Vemuganti – Full Stack Developer"
    >
      <SEO />
      <div className="indexContent">
        <div className="wrapper">
          <div className="splashMsg" id="main">
            Hi, I&apos;m{" "}
            <strong className="bigger">
              Krishna Prasanth <span className="special">Vemuganti</span>
            </strong>
            <br />
            a full stack developer from
            <br />
            Austin, Texas.
            <div className="right">
              <Link to="/home">
                <button className="homeButton" name="Home" aria-label="Home">
                  <FaArrowRight />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Index
